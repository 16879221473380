.single-product-container {
  padding: 20px;
  background-color: white;
  height: max-content;
}

.single-product-ad {
  width: 100%;
  margin-bottom: 10px;
}
.single-product {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.single-product-image {
  object-fit: contain;
  width: 300px;
  height: max-content;
}

.single-product-info {
  padding-left: 20px;
}

.single-product-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.single-product-rating {
  margin-top: 10px;
  display: flex;
}

.single-product-price {
  margin-top: 10px;
  color: #000;
}

.single-product-info > button {
  background: #000;
  border: 1px solid;
  margin-top: 40px;
  min-width: 150px;
  color: white;
  border-color: #a88734 #9c7e31 #846a29;
  cursor: pointer;
}

.single-product-description {
  margin-top: 20px;
  color: #000;
}

.single-product-specification {
  margin-top: 20px;
  color: #000;
}

i {
  vertical-align: middle;
}
.form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin: 1rem 0;
}
/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  /*background-color: #203040;*/
  background-color: white;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/*.card {
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  background-color: #c0c0c0 solid;
  border-radius: 0.5rem;
  margin: 1rem;
}
.card-body {
  padding: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}*/