.register {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register > p {
  margin-top: 10px;
  color: darkgray;
}

.register-logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}

.register-container {
  width: 350px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}

.register-container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
  color: #000;
}

.register-container > form > h5 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
}

.register-container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: #fff;
  width: 98%;
}

.register-container > p {
  margin-top: 15px;
  font-size: 12px;
}

.continue {
  background: #000;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  color: white;
  border-color: #a88734 #9c7e31 #846a29;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.detail {
  display: flex;
  margin-top: 15px;
  border-top: 1px solid lightgray;
  color: #000;
}

.detail p {
  margin-top: 10px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #000;
}

.signin-link {
  margin-left: 5px;
  font-size: 14px;
  text-decoration: none;
}
