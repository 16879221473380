.checkout {
  /*display: flex;*/
  padding: 20px;
  background-color: white;
  height: max-content;
}

.checkout-ad {
  width: 100%;
  margin-bottom: 10px;
}

.checkout-title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
