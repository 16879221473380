.checkout-product {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkout-product-image {
  object-fit: contain;
  width: 180px;
  height: 180px;
}

.checkout-product-info {
  padding-left: 20px;
}

.checkout-product-title {
  font-size: 20px;
  font-weight: 700;
}

.checkout-product-rating {
  display: flex;
}

.checkout-product-info > button {
  background: #000;
  border: 1px solid;
  margin-top: 10px;
  width: 180px;
  color: white;
  cursor: pointer;
  border-color: #a88734 #9c7e31 #846a29;
}

i {
  vertical-align: middle;
}
