.subtotal {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 300px;
  height: 150px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
  justify-content: space-between;
}

.subtotal-gift {
  display: flex;
  align-items: center;
}

.subtotal-gift > input {
  margin-right: 5px;
}

.subtotal > button {
  background: #000;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  color: white;
  border-color: #a88734 #9c7e31 #846a29;
  cursor: pointer;
}
