.scroll-to-top {
  /*position: fixed;*/
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.back-top-container {
  height: 40px;
  width: 1900px;
  background-color: white;
}
/*.back-top-container {
      height: 40px;
      text-align: center;
      width: 1470px;
      background-color: #37475a;
      color: white;
      margin-right: 15px;
    }*/
